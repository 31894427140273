import { ref } from 'vue';

import { useGlobalContentStore } from '~/store/globalContent';
import useStatefulCookie from '~/composeables/useStatefulCookie';
import { useUiStore } from '~/store/ui';
const cachedResponses = {} as any;
const doNotCacheUrls = ['basket', 'checkout', 'payment', '/me', 'reviews', '/stock-alerts'];
const lastError = ref(null) as any;
const lastErrorCode = ref<number | null>(null);
const lastResponse = ref(null) as any;
const lastResponseCode = ref<number | null>(null);
const pending = ref(false);

type AdditionalHeaders = {
  [key: string]: string;
}

export default function useApiFetch() {
  const { $t } = useNuxtApp();

  const accountToken = useStatefulCookie('userToken', { maxAge: 3600 * 24 * 30 * 12, path: '/' });

  const runtimeConfig = useRuntimeConfig();

  const apiGet = async<T>(url: string, additionalHeaders? : null | AdditionalHeaders, fromCache = true) : Promise<T> => {
    return req<T>(url, 'GET', null, additionalHeaders, fromCache);
  };

  const apiPost = async<T>(url: string, body?: null | object, additionalHeaders? : null | AdditionalHeaders, fromCache = false) : Promise<T> => {
    return req<T>(url, 'POST', body, additionalHeaders, fromCache);
  };

  const apiPut = async<T>(url: string, body?: null | object, additionalHeaders? : null | AdditionalHeaders) : Promise<T> => {
    return req<T>(url, 'PUT', body, additionalHeaders);
  };

  const apiDelete = async<T>(url: string, body?: null | object, additionalHeaders? : null | AdditionalHeaders) : Promise<T> => {
    return req<T>(url, 'DELETE', body, additionalHeaders);
  };

  const req = async<T>(url: string, method: string, body?: null | object, additionalHeaders? : null | AdditionalHeaders, fromCache = true) : Promise<T | any> => {
    pending.value = true;
    const uiStore = useUiStore();
    uiStore.clearFormErrorMsg();
    const cacheKey = url + (body ? JSON.stringify(body) : '');
    if (fromCache && process.client && cachedResponses[cacheKey]) {
      pending.value = false;

      return cachedResponses[cacheKey] as T;
    }
    lastResponse.value = null;
    lastResponseCode.value = null;
    lastError.value = null;
    lastErrorCode.value = null;
    const globalContent = useGlobalContentStore();
    try {
      const fullUrl = `${runtimeConfig.public.apiUrl}${url}`;
      if (globalContent.getEnvironment === 'local') {
        process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = '0';
      }

      const res = await $fetch<T>(fullUrl, {
        async onRequest({ options }) {
          options.method = method;
          options.headers = {
            'Accept': 'application/json',
            'HostName': globalContent.hostname,
            'BasePath': globalContent.getStoryblokBasePath,
            'ApplicationId': globalContent.getApplicationId,
          };

          if (accountToken.value && !additionalHeaders?.Authorization) {
            options.headers.Authorization = `Bearer ${accountToken.value}`;
          }
          if (body) {
            options.body = JSON.stringify(body);
          }
          if (additionalHeaders) {
            options.headers = Object.assign(options.headers, additionalHeaders);
          }
        },
        async onResponse({ response }) {
          if (!response.ok) {
            lastError.value = response._data;
            lastErrorCode.value = response.status || null;
            return null;
          }
          lastResponseCode.value = response.status || null;
          return response._data;
        },
      });

      if (process.client && !doNotCacheUrls.some(doNotCache => url.includes(doNotCache))) {
        cachedResponses[cacheKey] = res;
      }

      lastResponse.value = res;
      return res as T;

    } catch (e) {
      console.error(e);
      return null;
    } finally {
      pending.value = false;
    }
  };

  const handleApiError = (context?: string, forceFormError?: boolean, fadeOutErrorMessage?: boolean, forceGlobalError?: boolean) => {
    console.error('handleApiError');
    const uiStore = useUiStore();
    if (lastErrorCode.value === 403) {
      uiStore.setTemporaryError($t('apiError.unauthorized'), fadeOutErrorMessage ? 5000 : 0);
    } else if ((lastErrorCode.value === 422 || forceFormError) && !forceGlobalError) {
      uiStore.setFormErrorMsg(lastError.value, context);
    } else {
      if (lastError?.value?.message) {
        uiStore.setTemporaryError(lastError.value.message, fadeOutErrorMessage ? 5000 : 0);
      } else {
        uiStore.setTemporaryError($t('apiError.unknown'), fadeOutErrorMessage ? 5000 : 0);
      }
    }
  };

  return {
    apiGet,
    apiPost,
    apiPut,
    apiDelete,
    lastError,
    lastErrorCode,
    lastResponse,
    lastResponseCode,
    pending,
    accountToken,
    handleApiError,
  };
}

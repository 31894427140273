import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/opt/atlassian/pipelines/agent/build/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_iGLjBbor9q from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_BqLkNUef68 from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_KlVwwuJRCL from "/opt/atlassian/pipelines/agent/build/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import i18n_VfGcjrvSkj from "/opt/atlassian/pipelines/agent/build/plugins/i18n.ts";
import parse_izc2Y3T2GK from "/opt/atlassian/pipelines/agent/build/plugins/parse.ts";
import sentry_client_shVUlIjFLk from "/opt/atlassian/pipelines/agent/build/plugins/sentry.client.ts";
import storyblok_renderer_rz7uMy307m from "/opt/atlassian/pipelines/agent/build/plugins/storyblok-renderer.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_iGLjBbor9q,
  plugin_BqLkNUef68,
  plugin_KlVwwuJRCL,
  chunk_reload_client_UciE0i6zes,
  i18n_VfGcjrvSkj,
  parse_izc2Y3T2GK,
  sentry_client_shVUlIjFLk,
  storyblok_renderer_rz7uMy307m
]
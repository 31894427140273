import { defineStore } from 'pinia';
import {
  SitemapItem,
  StoryBlokAnyContentPage,
  StoryBlokApiResonseBrandPages,
  StoryBlokApiResponseSiteSettings,
  StoryBlokBrandPage,
  StoryBlokConfig,
  StoryBlokDataSourceApiResponse,
  StoryBlokGlobalFooter,
  StoryBlokHeaderData,
  StoryBlokNestableNav,
  StoryBlokSiteNoticeData,
  StoryBlokSiteSettingsCombined
} from '~/constants/types/storyblok';

import { DomainMapper, findDomainByHostAndPath } from '~/util/domain-mapper';
import useLinkReplacer from '~/composeables/useLinkReplacer';
import { CrossDockingPrices, FilterConfiguration } from '~/constants/types/algolia';
import useApiFetch from '~/composeables/useApiFetch';

const { replaceLinksInStoryBlokResponse } = useLinkReplacer();

type Footer = null | StoryBlokGlobalFooter;
type Header = null | StoryBlokHeaderData;
type Config = null | StoryBlokConfig;
type SiteNotice = null | StoryBlokSiteNoticeData;
type DomainMap = null | DomainMapper;

export type StoriesEntryLimited = {
  id: number;
  name: string;
  parent_id: number;
  uuid: string;
  real_path: string;
}
export type BrandItem = {
  brandCode: string,
  brandName: string,
  brandFlag: string;
  brandLink: string;
  brandCategory: string;
  brandLogo: string | null;
  showInAutomaticList: boolean;
}

export const useGlobalContentStore = defineStore('globalContentStore', {
  state: () => ({
    articleKeywords: [] as string[],
    config: null as Config,
    countryDomains: [] as { flagUrl: string, countryCode: string, countryUrl: string }[],
    currentStory: null as null | StoryBlokAnyContentPage,
    domainMap: null as DomainMap,
    footer: null as Footer,
    header: null as Header,
    hostname: '' as string,
    pageId: -1, // storyblok
    pageUuid: '', // storyblok page uuid
    siteNotice: null as SiteNotice,
    stories: [] as StoriesEntryLimited[],
    translations: {} as { [key: string]: string; },
    filterSettings: { filterValues: [], filters: [], sortIndices: [], translations: {}, flagSuffix: '' } as FilterConfiguration,
    filterSettingsLoaded: false,
    sitemap: [] as SitemapItem[],
    sitemapLoaded: false,
    brandList: [] as BrandItem[],
    brandListLoaded: false,
    pointCollectingThreshold: 1000,
    cv: null as number | null,
    crossDockingPrices: null as null | CrossDockingPrices,
  }),
  actions: {
    async loadBrands() {
      if (this.brandListLoaded) {
        return true;
      }
      const storyblokApi = useStoryblokApi();
      const folder = !this.isSubFolder ? `${this.getStoryblokBasePath}${this.config?.brandsFolder}` : this.config?.brandsFolder;
      const res = await storyblokApi.get('cdn/stories/', {
        version: useRuntimeConfig().public.storyblokStoriesVersion as 'draft' | 'published',
        starts_with: folder,
        per_page: 100,
        excluding_fields: 'seo,body,feedSettings,visualAppearance,_editable',
        content_type: 'BrandPage',
        //sort_by: 'content.brandName', //storyblock doesent seem to get åäö
      }) as StoryBlokApiResonseBrandPages;

      if (res) {
        this.brandList = replaceLinksInStoryBlokResponse<StoryBlokBrandPage[]>(res.data.stories).filter((f) => f.content.showInAutomaticList)
          .sort((a, b) => {
            const aFix = a.content.brand?.brand?.name || '';
            const bFix = b.content.brand?.brand?.name || '';
            return aFix.trim().localeCompare(bFix.trim(), this.domainMap?.locale || 'sv');
          })
          .map((i) => {
            return {
              brandCode: i.content.brand?.brand?.id || '',
              brandName: i.content.brand?.brand?.name?.trim() || '',
              brandFlag: i.content.brandFlag?.trim() || '',
              brandCategory: i.content.brandCategory?.trim() || '',
              brandLink: i.full_slug,
              brandLogo: i.content.brandLogo?.filename || null,
              showInAutomaticList: i.content.showInAutomaticList || false,
            };
          })
          .filter((f) => f.brandCode !== '' && f.brandName !== '') as BrandItem[];
        this.brandListLoaded = true;
        return true;
      } else {
        console.error('Brand?');
        return false;
      }

    },
    async loadSitemap() {
      if (this.sitemapLoaded) {
        return true;
      }
      const { apiGet, handleApiError } = useApiFetch();
      const res = await apiGet<SitemapItem[]>(
        `/applications/breadcrumbs?type=${useRuntimeConfig().public.storyblokStoriesVersion}`
      );
      if (!res) {
        handleApiError();
      } else {
        this.sitemap = res;
        this.sitemapLoaded = true;
      }
      return true;
    },
    async loadSiteSettings() {
      if (this.filterSettingsLoaded) {
        return true;
      }
      const { apiGet, handleApiError } = useApiFetch();
      const res = await apiGet<FilterConfiguration>(`/applications/configuration?lang=${this.getLocale}`);

      if (!res) {
        handleApiError();
        const lang = await import('~/lang/local.json');
        this.translations = lang.default;
      } else {

        if (this.getEnvironment !== 'production') {
          const lang = await import('~/lang/local.json');
          this.translations = {
            ...res.translations,
            ...lang.default,
          };
        } else {
          this.translations = res.translations;
        }

        res.translations = {};
        this.filterSettings = res;
        this.filterSettingsLoaded = true;

        if (res.crossDocking) {
          this.crossDockingPrices = res.crossDocking;
        }
      }
      return true;
    },
    async setCorrectLangAndMarket() {
      const hostname = useRequestHeaders(['host'])?.host ?? '';
      const domainMap = findDomainByHostAndPath(hostname, useRoute().path);
      this.hostname = hostname;
      this.domainMap = domainMap;
    },
    async loadFromCms() {
      const storyblokApi = useStoryblokApi();
      const runtimeConfig = useRuntimeConfig();
      const res = await storyblokApi.get('cdn/stories/', {
        version: runtimeConfig.public.storyblokStoriesVersion as 'draft' | 'published',
        starts_with: this.getStoryblokBasePath.length ? `${this.domainMap?.storyblokBasePath}/sitesettings/` : 'sitesettings/',
        resolve_links: 'url', // Warining: if you change this to 'story' or '1' the hydration will be over 1mb (~ 400% more than current)
        filter_query: {
          component: { not_in: 'SalesToolsSettings' },
        },
        cv: Date.now(),
      }) as StoryBlokApiResponseSiteSettings;
      this.cv = res.data.cv;

      const globalData = replaceLinksInStoryBlokResponse<StoryBlokSiteSettingsCombined[]>(res.data.stories);

      const footer = globalData.find((i) => {
        return i.content.component === 'GlobalFooter';
      });

      if (footer) {
        this.footer = footer.content as StoryBlokGlobalFooter;
      } else {
        console.warn('Couldn\'t find Footer in globalContentStore');
      }

      const navigation = globalData.find((i) => {
        return i.content.component === 'HeaderData';
      });

      if (navigation) {
        this.header = navigation.content as StoryBlokHeaderData;
      } else {
        console.warn('Couldn\'t find Navigation in globalContentStore');
      }

      const config = globalData.find((i) => {
        return i.content.component === 'Config';
      });

      if (config) {
        this.config = config.content as StoryBlokConfig;
      } else {
        console.warn('Couldn\'t find Config in globalContentStore');
      }

      const siteNotice = globalData.find((i) => {
        return i.content.component === 'SiteNoticeSettings';
      });

      if (siteNotice) {
        this.siteNotice = {
          ...siteNotice.content,
          hideSiteMessage: false,
        } as StoryBlokSiteNoticeData;
      } else {
        console.warn('Couldn\'t find Site Notice in globalContentStore');
      }
    },
    async getArticleKeywords() {
      if (this.articleKeywords.length === 0) {
        const storyblokApi = useStoryblokApi();
        const res = await storyblokApi.get('cdn/datasource_entries', {
          datasource: 'article-keywords',
        }) as StoryBlokDataSourceApiResponse;

        if (res && res.data.datasource_entries.length) {
          this.articleKeywords = res.data.datasource_entries.map((i) => {
            return i.value;
          });
        }
      }
      return this.articleKeywords;
    },
    setPageUuid(payload: string) {
      this.pageUuid = payload;
    },
    setPageId(payload: number) {
      this.pageId = payload;
    },
    setCurrentStory(payload: StoryBlokAnyContentPage) {
      this.currentStory = payload;
    },
    setHideSiteMessage(payload: boolean) {
      if (this.siteNotice) {
        this.siteNotice = {
          ...this.siteNotice,
          hideSiteMessage: payload,
        };
      }
    },
  },
  getters: {
    isSubFolder(state) {
      return state.domainMap?.subFolder ?? false;
    },
    baseHref(state) {
      return state.domainMap?.url ?? '/';
    },
    getPrependPathForManualLinksWithoutLeadingSlash(state) {
      return (state.domainMap?.subFolder ?? false) ? `${state.domainMap?.storyblokBasePath}` : '';
    },
    getPrependPathForManualLinks(state) {
      return (state.domainMap?.subFolder ?? false) ? `/${state.domainMap?.storyblokBasePath}` : '';
    },
    getSitemapItemById: (state) => (id: number) => {
      return state.sitemap.find((f) => f.id === id);
    },
    getSitemapItemBySlug: (state) => (slug: string, stripSlash = false) => {
      if (stripSlash && slug.at(-1) === '/') {
        slug = slug.slice(0, -1);
      }
      return state.sitemap.find((f) => f.full_slug === slug);
    },
    getSitemapItemByParentId: (state) => (id: number) => {
      return state.sitemap.filter((f) => f.parent_id === id);
    },
    getSitemapIndexPageFromFolder: (state) => (folderId: number) => {
      return state.sitemap.find((f) => f.parent_id === folderId && f.is_startpage);
    },
    getFilterOption: (state) => (key: string) => {
      const output = state.filterSettings.filterValues.find((f) => f.code === key);
      if (output) {
        return output;
      }
      console.warn('couldnt find filter option in translation. Key: '+key);
      return  {
        name: key,
        code: key,
        parentCode: null,
        description: null,
        image: null,
      };
    },
    getFilterName: (state) => (key: string): string => {
      const output = state.filterSettings.filters.find((f) => f.code === key);
      if (output) {
        return output.name;
      }
      return  key;
    },
    getNavByUid: (state) => (
      _uid:string,
      findIn: 'main' | 'secondary' | StoryBlokNestableNav[] = 'main'): StoryBlokNestableNav | null => {

      function findNode(_uid: string, array: StoryBlokNestableNav[]) : null | StoryBlokNestableNav  {
        for (const node of array) {
          if (node._uid === _uid) return node;
          if (node.children) {
            const child = findNode(_uid, node.children);
            if (child) return child;
          }
        }
        return null;
      }
      if (findIn === 'main') {
        if (!state.header?.mainNavigation) {
          return null;
        }
        return findNode(_uid, state.header.mainNavigation);
      }
      if (findIn === 'secondary') {
        if (!state.header?.secondaryNavigation) {
          return null;
        }
        return findNode(_uid, state.header.secondaryNavigation);
      }
      return findNode(_uid, findIn);

    },
    getNavChildrenByUid: (state) => (parentUid: string): StoryBlokNestableNav[] => {
      const children = state.header?.mainNavigation.find((f)=>f._uid === parentUid)?.children;
      if (children) {
        return children;
      }
      return [];
    },
    getNavigation: (state) => (nav: 'mainNavigation' | 'secondaryNavigation') => {
      if (!state.header?.[nav]) {
        return [];
      }
      return state.header[nav];
    },
    getFooterNav: (state) => {
      if (!state.footer?.navigation) {
        return [];
      }
      return state.footer.navigation;
    },
    hideSiteMessage(state) {
      return state.siteNotice?.hideSiteMessage;
    },
    footerNavigation(state) {
      return state.footer?.navigation || [];
    },
    getFooter(state) {
      return state.footer as StoryBlokGlobalFooter;
    },
    articlesFolder(state) {
      return state.config?.articlesFolder || '';
    },
    brandlistPage(state) {
      return state.config?.brandlistPage || '';
    },
    getStory: (state) => (id: number) => {
      return state.stories?.find((i) => i.id === id);
    },
    getCurrentUrl(state) {
      const { fullPath } = useRoute();
      return `https://${state.hostname}${fullPath}`;
    },
    getCountryCode(state) {
      return state.domainMap?.countryCode || '';
    },
    getCountryUrl(state) {
      return state.domainMap?.url || '';
    },
    getEnvironment(state) {
      return state.domainMap?.environment || 'local';
    },
    getFlagUrl(state) {
      return state.domainMap?.flagUrl || '';
    },
    getLocale(state) {
      return state.domainMap?.locale || '';
    },
    getCurrency(state) {
      return state.domainMap?.currency || '';
    },
    getLogoUrl(state) {
      return state.domainMap?.logoUrl || '';
    },
    getStoryblokBasePath(state) {
      return state.domainMap?.storyblokBasePath || '';
    },
    getProductBasePath(state) {
      return state.domainMap?.productBasePath || 'produkt';
    },
    getAlgoliaIndex(state) {
      return state.domainMap?.algoliaIndex || '';
    },
    getAlgoliaIndexAutocomplete(state) {
      return state.domainMap?.algoliaIndexAutocomplete || '';
    },
    getAlgoliaIndexPages(state) {
      return state.domainMap?.algoliaIndexPages || '';
    },
    getApplicationId(state) {
      return state.domainMap?.applicationId || '';
    },
    getBrandName: (state) => (code: string) => {
      const name = state.brandList.find((f: BrandItem) => f.brandCode === code);
      return name?.brandName || '';
    },
    getBrandLink: (state) => (code: string): string | null => {
      const name = state.brandList.find((f: BrandItem) => f.brandCode === code);
      if (!name?.brandLink) {
        return null;
      }
      return name.brandLink.startsWith('/') ? name.brandLink : '/' + name.brandLink;
    },
    getBrandFlag: (state) => (code: string): string => {
      const name = state.brandList.find((f: BrandItem) => f.brandCode === code);
      return name?.brandFlag || '';
    },
    getSearchSuggestions(state) {
      return state.header?.popularTerms.split(',').map((m) => m.trim());
    },
    getFreeShippingThreshold(state) {
      return state.config?.freeShippingThreshold ?? 700;
    },
    getPointCollectingThreshold(state) {
      return state.pointCollectingThreshold;
    },
    getBrandPageLink(state) {
      if (state.config?.brandlistPage) {
        const { $sbLinkFix } = useNuxtApp();
        return $sbLinkFix(state.config?.brandlistPage);
      }
      return '';
    },
    getAllProductsLink(state) {
      if (state.config?.allProductsPage) {
        const { $sbLinkFix } = useNuxtApp();
        return $sbLinkFix(state.config?.allProductsPage);
      }
      return '';
    },
    getAltDeliveryTextCategories(state) {
      return state.config?.altDeliveryTextCategories?.split(',') || [];
    },
  },
});

import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const { public: { sentry, environment } } = useRuntimeConfig();

  // If disabled and environment is development, ignore and warn in the console
  const disabledInDevelopment = true;
  if (!sentry.dsn || (environment === 'development' && disabledInDevelopment)) {
    console.warn('Sentry DSN not set, skipping Sentry Client initialization');
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 0.02,

    tracePropagationTargets: ['localhost'],

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.02,
  });
});

import { useGlobalContentStore } from '~/store/globalContent';

export default function useLinkReplacer() {
  const replaceLinksInStoryBlokResponse = <T>(obj: any): T => {
    const globalContent = useGlobalContentStore();

    if (globalContent.isSubFolder) {
      return <T>obj;
    }

    const clonedObj = JSON.parse(JSON.stringify(obj)
    // First replace all .se/.dk etc
      .replaceAll(`.${globalContent.getStoryblokBasePath}`, '<<rep>>')
      .replaceAll(`${globalContent.getStoryblokBasePath}/`, '/')
      .replaceAll('<<rep>>', `.${globalContent.getStoryblokBasePath}`)
      .replaceAll('"//', '"/')
    );
    obj = Object.assign(obj, clonedObj);
    return <T>obj;
  };

  const replaceLinksInPath = (path: string) : string => {
    const globalContent = useGlobalContentStore();
    if (globalContent.isSubFolder) {
      return path;
    }
    const route = useRoute();
    const isInStoryblokAdmin = (route.query && route.query._storyblok && route.query._storyblok !== '');
    if (!isInStoryblokAdmin && path && path.startsWith(`${globalContent.getStoryblokBasePath}/`)) {
      path = path.replace(`${globalContent.getStoryblokBasePath}/`, '');
    }
    return isInStoryblokAdmin ? path : `${globalContent.getStoryblokBasePath}/${path}`;
  };

  const manualUrl = (url: string|null|undefined) : string => {
    if (!url) {
      return '';
    }
    const globalContent = useGlobalContentStore();
    if (url !== '/' && url.startsWith(`${globalContent.getStoryblokBasePath}/`)) {
      return `${url}`;
    }
    return `${globalContent.getPrependPathForManualLinks}${url.startsWith('/') ? '' : '/'}${url}`;
  };

  const productUrl = (url: string) : string => {
    const globalContent = useGlobalContentStore();
    return `${globalContent.getPrependPathForManualLinks}/${globalContent.getProductBasePath}/${url}`;
  };

  const accountUrl = (url?: string) : string => {
    const globalContent = useGlobalContentStore();
    return `${globalContent.getPrependPathForManualLinks}/account/${url}`;
  };

  const salesUrl = (url?: string) : string => {
    const globalContent = useGlobalContentStore();
    return `${globalContent.getPrependPathForManualLinks}/salestool/${url}`;
  };

  return {
    replaceLinksInStoryBlokResponse,
    replaceLinksInPath,
    productUrl,
    accountUrl,
    salesUrl,
    manualUrl,
  };
}

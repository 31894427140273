/**
 * @important - typing for nuxt plugins is in index.d.ts
 */

import { StoryBlokLink } from '~/constants/types/storyblok';
import { useGlobalContentStore } from '~/store/globalContent';
import useLinkReplacer from '~/composeables/useLinkReplacer';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      sbLinkCheck(input: StoryBlokLink): boolean {
        if ((input.story?.url && input.story.url !== '') || input.cached_url !== '' || input.url !== '') {
          return true;
        }
        return false;
      },
      manualUrl(url: string|null|undefined): string {
        const { manualUrl } = useLinkReplacer();
        return manualUrl(url);
      },
      sbLinkFix(input: StoryBlokLink|string): string {
        if (!input) {
          return '';
        }
        const globalContent = useGlobalContentStore();
        if (typeof input === 'string') {
          return globalContent.isSubFolder ? `/${input}` : input;
        }
        let output = '';
        if (input.story?.url && input.story.url !== '') {
          output = input.story?.url;
        }
        if (input.cached_url !== '') {
          output = input.cached_url;
        }
        if (input.url !== '') {
          output = input.url;
        }
        if (input.linktype === 'email') {
          return `mailto:${output}`;
        }
        if (!globalContent.isSubFolder ||
        input.linktype === 'url' ||
        input.linktype === 'asset') {
          if (!output.startsWith('http') && !output.startsWith('/')) {
            return `/${output}`;
          }
          return output;
        }
        return `/${output}`;
      },
      sbLinkTarget(input: StoryBlokLink): string|null {
        if (!input) {
          return null;
        }
        if (input.target && input.target.trim() !== '') {
          return input.target;
        }
        if (input.linktype === 'asset') {
          return '_blank';
        }
        if (input.linktype === 'url' && input.url.startsWith('http')) {
          return '_blank';
        }
        return null;
      },
      toNumber(value: string): number {
        try {
          const parsedString = parseFloat(value.replace(/\s/g, '').replace(/,/g, ''));
          return parsedString;
        } catch (e) {
          console.warn(e);
          // @ts-ignore
          return value;
        }
      },

      /**
       * Fixes javascript floating point issues
       * @example $floatingPoint(79-63.2)
       * From this answer
       * @see https://github.com/maslianok/js-floating-point/blob/master/src/index.js
       */
      floatingPoint(value: any, recurringSymbols = 6) {
        if (!value || Number.isNaN(parseFloat(value))) {
          // value is wrong or empty
          return value;
        }

        const [intPart, decimalPart] = `${value}`.split('.');

        if (!decimalPart) {
          // no decimal part
          return value;
        }

        const regex = new RegExp(
          `(9{${recurringSymbols},}|0{${recurringSymbols},})(\\d)*$`,
          'gm'
        );
        const matched = decimalPart.match(regex);

        if (!matched) {
          // no floating-point bug
          return value;
        }

        const [wrongPart] = matched;
        const correctDecimalsLength = decimalPart.length - wrongPart.length;
        return parseFloat(
          parseFloat(`${intPart}.${decimalPart}`).toFixed(correctDecimalsLength)
        );
      },

    },
  };
});

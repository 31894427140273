import { useGlobalContentStore } from '~/store/globalContent';

export default function useStripFlagSuffix() {

  const stripFlagSuffix = (flagCode: string | null | undefined) => {
    if (!flagCode) {
      return flagCode;
    }
    const globalContent = useGlobalContentStore();
    return flagCode?.replace(globalContent?.filterSettings?.flagSuffix, '');
  };

  return {
    stripFlagSuffix,
  };
}

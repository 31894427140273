import { useCookie, useState, watch } from '#imports';
import { CookieOptions, CookieRef } from '#app';

export default function useStatefulCookie<T = string|null>(name: string, opt?: CookieOptions<T>): CookieRef<T> {
  const cookie = useCookie<T>(name, opt);
  const state = useState<T>(name, () => cookie.value);

  watch(state, () => { cookie.value = state.value; }, { deep: true });

  return state;
}

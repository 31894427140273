import Product from '~/models/product';
import { defineStore } from 'pinia';
import useApiFetch from '~/composeables/useApiFetch';
import { DataLayerProduct, pushToDataLayer } from '~/composeables/useTracking';
import { PriceHistory, PriceHistoryItem } from '~/constants/types/norce';
import useAlgoliaFetch from '~/composeables/useAlgoliaFetch';
import { ProductRaw } from '~/constants/types/algolia';
import { useGlobalContentStore } from '~/store/globalContent';

type ThisPriceHistory = PriceHistory & {
  partNo: string;
}

export const useProductStore = defineStore('productStore', {
  state: () => ({
    priceHistory: [] as ThisPriceHistory[],
    batchTrackProducts: [] as DataLayerProduct[],
  }),
  actions: {
    async loadPriceHistory(partNo: string) {
      const { apiGet, lastError } = useApiFetch();

      if (this.priceHistory.find((i) => i.partNo === partNo)) {
        return true;
      }
      const getPrices = await apiGet<PriceHistory>(`/products/${partNo}/price-history`, null, false);
      if (getPrices) {
        this.priceHistory.push({
          partNo: partNo,
          prices: getPrices.prices?.filter(p => parseInt(p.price, 10) !== 0).reverse(),
        });
        return true;
      } else {
        console.error(lastError);
        return false;
      }
    },
    /**
     * @todo - thes product doesn't seem to have anything to do with the stor, should be moved to useAlgoliaFetch
     */
    async getRelatedProducts(partNos: string[]) {
      const { getProductsByPartNo, convertRawToProducts } = useAlgoliaFetch();

      return await getProductsByPartNo(partNos).then((data) => {
        if (!data || !data.hits.length) {
          return [];
        }
        return convertRawToProducts(data.hits).map(p => p.product);
      });
    },
    /**
     * @todo - thes product doesn't seem to have anything to do with the stor, should be moved to useAlgoliaFetch
     */
    async getProductBrandRelatedProducts(product: ProductRaw) {
      const { getProductByBrandAndCategory } = useAlgoliaFetch();

      const categoryCodes = product.categories.map(c => c.code);

      return await getProductByBrandAndCategory<ProductRaw[]>(product.brand.code, categoryCodes).then((data) => {
        return data && data.hits ? data.hits.map((p: ProductRaw) => Product.create(p)).filter(p => p.partNo !== product.partNo) : [];
      });
    },
    async sendProductImpression(partNo: string) {
      const { apiPost, lastError } = useApiFetch();

      const productImpression = await apiPost(`/products/${partNo}/product-impressions`);
      if (productImpression) {
        return true;
      } else {
        console.error(lastError);
        return false;
      }
    },
    /**
     * Collects items tracked with v-track and sends when we reach threshold
     */
    batchTrack(item: DataLayerProduct) {
      this.batchTrackProducts.push(item);
      if (this.batchTrackProducts.length >= 5) {
        this.sendBatch();
      }
    },
    sendBatch() {
      const globalContent = useGlobalContentStore();

      if (this.batchTrackProducts.length > 0) {
        pushToDataLayer({
          event: 'view_item_list',
          ecommerce: {
            currency: globalContent.getCurrency,
            items: this.batchTrackProducts,
          },
        });

        this.batchTrackProducts = [];
      }
    },
  },
  getters: {
    getPriceHistoryLowest: (state) => (partNo: string): PriceHistoryItem | null => {
      const { $toNumber } = useNuxtApp();
      const prices = state.priceHistory.find((i) => i.partNo === partNo);
      if (prices && prices.prices.length) {
        const sorted = prices.prices.sort((a,b) => $toNumber(a.price) - $toNumber(b.price));
        return sorted[0];
      }
      return null;
    },
  },
});
